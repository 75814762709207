@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}


// ==================================================
//  Body
// ==================================================
body {
  font-family: YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-weight: 500;

  font-size: 1.6rem;
  line-height: 1.8;
  min-width: 1120px;

  @include mixin.sp {
    font-size: 1.4rem;
    min-width: 375px;
  }
}


// ==================================================
//  Load Animation
// ==================================================
.loadAnimation {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: color.$bg-white;

  &_symbol {
    display: block;
    width: 113px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mixin.sp {
      width: 100px;
    }
  }

  // Javascript
  &.jsLoadAnimation {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.45s, visibility 0.45s;

    &-loaded {
      opacity: 0;
      visibility: hidden;
    }
  }
}
// Javascript
.jsLoadAnimationBody {
  opacity: 0;
  transition: opacity 0.45s;

  &-loaded {
    opacity: 1;
  }
}


// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  width: 100%;
  min-width: 1120px;
  color: color.$write-white;
  background-color: color.$site-main;
  padding: 9px 0 12px;
  position: fixed;
  z-index: 80;
  top: 0;
  left: 0;

  @include mixin.sp {
    min-width: 375px;
    padding: 10px 10px 13px;
  }

  &_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }

  &_menuButton {
    display: none;

    @include mixin.sp {
      display: block;
      width: 39px;
      height: 39px;
      text-indent: 100%;
      overflow: hidden;
      position: relative;
      z-index: 90;
      background-image: url(../img/common/icon_menu.png);
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      margin: 4px 8px 0 0;
    }
  }

  &_logo {
    display: block;

    &_description {
      display: block;
      font-size: 1.5rem;
      font-weight: 700;

      @include mixin.sp {
        font-size: 1.1rem;
      }
    }

    &_image {
      display: block;
      width: 360px;
      margin: 6px 0 0;

      @include mixin.sp {
        max-width: 240px;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }
  }

  &_menu {
    display: flex;
    align-items: center;

    @include mixin.sp {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 85;
      top: 0;
      left: 0;
      background-color: color.$site-main;
    }

    // Javascript
    &.js_headerMenu {
      @include mixin.sp {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.3s, opacity 0.3s;
      }

      &-active {
        @include mixin.sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_navigation {
      &_list {
        display: flex;
        align-items: baseline;
        column-gap: 42px;
        margin: 0 31px 0 0;
        padding: 0;
        list-style: none;

        @include mixin.sp {
          column-gap: 0;
          row-gap: 60px;
          flex-direction: column;
          align-items: center;
          margin: 0 0 80px 0;
        }

        &_item {
          display: block;
          min-width: 104px;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 700;
          padding: 0 4.5px;

          @include mixin.sp {
            font-size: 2.0rem;
          }

          &::after {
            content: "";
            display: block;
            width: calc(100% + 9px);
            height: 4px;
            background-image: url(../img/common/bg_dashed.svg);
            background-size: auto 100%;
            background-position: left center;
            background-repeat: repeat-x;
            transform: translateX(-4.5px);
            margin-top: 2px;

            @include mixin.sp {
              width: calc(100% + 18px);
              transform: translateX(-9px);
              margin-top: 6px;
            }
          }

          &_inner {
            display: block;
            text-decoration: none;
            @include mixin.hoverOpacity60;
          }
        }
      }
    }

    &_tel {
      display: block;
      width: 41px;
      transform: translateY(3px);
      @include mixin.hoverOpacity60;

      & > img {
        display: block;
        width: 100%;
      }
    }
  }
}


// ==================================================
//  main
// ==================================================
main {
  margin-top: 100px;

  @include mixin.sp {
    margin-top: 80px;
  }
}


// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  color: color.$write-white;
  background-color: color.$site-main;
  margin-top: 155px;
  padding: 39px 0 34px;
  position: relative;

  @include mixin.sp {
    margin-top: 120px;
    padding: 16px 0;
  }

  &_inner {
    display: block;
    width: 100%;
    max-width: 1080px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;

    @include mixin.sp {
      max-width: calc(100% - 40px);
      padding: 0;
    }
  }

  &_btnTop {
    display: block;
    width: 143px;
    height: 44px;
    position: absolute;
    top: calc(-51px - 31.5px);
    right: 0;

    @include mixin.sp {
      top: -59.5px;
    }

    & > img {
      display: block;
      width: 100%;
    }
  }

  &_copyright {
    font-size: 1.5rem;
    margin: 0;

    @include mixin.sp {
      font-size: 1.3rem;
    }
  }
}


// ==================================================
//  Page Title, Paragraph
// ==================================================
.page {
  &TitleImage {
    display: block;
    height: 100px;
    margin: 0;

    @include mixin.sp {
      height: 60px;
    }

    & > img {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }

  &Paragraph {
    margin: 53px auto 0;
    text-align: center;
    max-width: calc(100% - 40px);

    @include mixin.sp {
      margin-top: 25px;
    }
  }
}


// ==================================================
//  Banner
// ==================================================
.banner {
  display: block;
  width: 100%;
  max-width: 275px;
  position: fixed;
  z-index: 90;
  right: 30px;
  bottom: 15px;
  @include mixin.hoverOpacity60;

  @include mixin.sp {
    display: none;
    max-width: 220px;
  }

  & > img {
    display: block;
    width: 100%;
  }
}